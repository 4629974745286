import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Button from '../../atoms/button';
import IconUserAccount from '../../atoms/icon-user-account';
import Typography from '../../atoms/typography';

const ButtonWrapper = styled(Button, { shouldForwardProp: (prop) => prop !== 'isLoggedIn' })`
  background-color: ${(props) => props.theme.color.backgroundLight};
  border-color: ${(props) => props.theme.color.backgroundLight};
  color: ${(props) => props.theme.color.brand};
  height: 4.4rem;
  letter-spacing: initial;
  outline: 0;
  padding: 1rem;
  text-transform: initial;

  svg {
    display: block;
    width: 20px;
    height: 20px;
    fill: ${({ isLoggedIn, theme }) => (isLoggedIn ? theme.color.brand : 'transparent')};
    stroke: ${({ theme }) => theme.color.brand};
    stroke-width: 2px;
  }

  @media (min-width: ${(props) => props.theme.breakpoint.large}) {
    svg {
      display: none;
    }
  }
`;

const Greeting = styled(Typography, { shouldForwardProp: (prop) => prop !== 'isLoggedIn' })`
  display: none;
  line-height: 1;
  color: ${({ isLoggedIn, theme }) => (isLoggedIn ? theme.color.brand : theme.color.brandA11yRed)};

  @media (min-width: ${(props) => props.theme.breakpoint.large}) {
    display: block;
  }
`;

const UserGreeting = (props) => (
  <ButtonWrapper {...props} isIcon>
    <IconUserAccount />
    <Greeting {...props} element="span" like="dec-1">
      {
        props.isLoggedIn
          ? `Hi, ${props.name || 'Friend'}`
          : 'Sign In'
      }
    </Greeting>
  </ButtonWrapper>
);

UserGreeting.defaultProps = {
  isLoggedIn: false,
  name: ''
};

UserGreeting.propTypes = {
  name: PropTypes.string,
  isLoggedIn: PropTypes.bool
};

UserGreeting.whyDidYouRender = true;

export default UserGreeting;
