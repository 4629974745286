import React, {
  createContext,
  useReducer,
  useContext,
  useEffect
} from 'react';
import PropTypes from 'prop-types';

const RecentContext = createContext();

const recentReducer = (items, action) => {
  switch (action.type) {
    case 'add': {
      const item = {
        id: action.value.trim(),
        label: action.value
      };

      if (!item.id || !item.label) return items;

      // if item exists in the array - remove it from existing array
      const temp = items.filter((x) => x.id !== item.id);
      temp.unshift(item);

      return [...temp];
    }

    default: {
      throw new Error(`Unhandled action type in RecentProvider: ${action.type}`);
    }
  }
};

const RecentProvider = ({ children, key }) => {
  const storage = (() => {
    const temp = global?.window?.localStorage.getItem(key);
    return temp ? JSON.parse(temp) : [];
  })();

  const [items, dispatch] = useReducer(recentReducer, storage);

  const addItem = (value) => dispatch({ type: 'add', value });

  useEffect(() => {
    global.window.localStorage.setItem(key, JSON.stringify(items));
  }, [key, items]);

  const value = { items: items.slice(0, 6), addItem, dispatch };
  return <RecentContext.Provider value={value}>{children}</RecentContext.Provider>;
};

RecentProvider.defaultProps = {
  key: 'RECENT_SEARCHES'
};

RecentProvider.propTypes = {
  children: PropTypes.node.isRequired,
  key: PropTypes.string
};

const useRecent = () => {
  const context = useContext(RecentContext);
  if (context === undefined) {
    throw new Error('useRecent must be used within a RecentProvider');
  }
  return context;
};

export { RecentProvider, useRecent };
