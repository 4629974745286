import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Container = styled.section((props) => ({
  '& + &': {
    marginTop: props.direction === 'row' ? 0 : props.theme.modularScale.large
  }
}));

export const Title = styled.h2((props) => ({
  color: props.theme.color.brand,
  fontFamily: props.theme.font.caption,
  fontSize: props.theme.modularScale.small,
  fontStyle: 'normal',
  fontWeight: 400,
  wordBreak: 'break-word',
  letterSpacing: 2.4,
  lineHeight: 1.5,
  marginBottom: props.theme.modularScale.base,
  textAlign: 'center',
  textTransform: 'uppercase',
  span: {
    textTransform: 'lowercase',
    '::before': { content: '"\u2018"' },
    '::after': { content: '"\u2019"' }
  },
  [`@media screen and (min-width: ${props.theme.breakpoint.small})`]: {
    textAlign: 'left'
  }
}));

const Section = (props) => (
  <Container {...props}>
    <Title isCentered={props.hasCenteredTitle}>
      {props.title}
      {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
      {props.term && (<> For <span>{props.term}</span></>)}
    </Title>

    {props.children}
  </Container>
);

Section.defaultProps = {
  children: null,
  hasCenteredTitle: false,
  term: '',
  direction: 'column'
};

Section.propTypes = {
  children: PropTypes.node,
  hasCenteredTitle: PropTypes.bool,
  term: PropTypes.string,
  title: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(['column', 'row'])
};

export default Section;
