import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Button from '../../atoms/button';
import Typography from '../../atoms/typography';

const StyledContainer = styled(Button)`
  display: inline-flex;
  font-family: ${(props) => props.theme.font.sans};
  line-height: 2rem;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: normal;
`;

const Selected = styled.div`
  ${(props) => props.theme.arrow(props.isActive ? 'up' : 'down', props.theme.color.brandA11yRed, 'right center', 4)}
  color: ${(props) => props.theme.color.brandA11yRed};
  font-size: ${(props) => props.theme.modularScale.small};
  padding: 0 1.2rem;
  position: relative;
`;

const Label = styled(Typography)`
  color: ${(props) => props.theme.color.brand};
  letter-spacing: 0.2em;
  text-transform: uppercase;
  line-height: 2rem;
`;

const PetiteProfileSelector = (props) => (
  <StyledContainer aria-label="open petite profile menu" styledLikeLink onClick={props.handleOnTriggerClicked}>
    <Label element="p" like="label-1">SHOP FOR</Label>
    { !props.mini && <Selected isActive={props.isActive}>Everything</Selected> }
    { props.mini && <Selected isActive={props.isActive}>{props.mini.name}</Selected>}
  </StyledContainer>
);
PetiteProfileSelector.defaultProps = {
  isActive: false,
  handleOnTriggerClicked: () => {}
};

PetiteProfileSelector.propTypes = {
  handleOnTriggerClicked: PropTypes.func,
  isActive: PropTypes.bool,
  mini: PropTypes.object.isRequired
};

PetiteProfileSelector.whyDidYouRender = true;

export default PetiteProfileSelector;
