import React from "react";
import styled from '@emotion/styled';

const LoadingContainerDiv = styled.div`
  position: relative;
  height: ${({ theme }) => theme.modularScale.four};
  margin-bottom: 1px;
`;

const LoadingBar = () => <LoadingContainerDiv id="loading-bar"/>

export default LoadingBar
